<template>
  <section>
    <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/service"
            ><a>Zlecenie serwisowe</a></router-link
          >
          <router-link
            tag="li"
            :to="{
              name: 'ServiceDetails',
              params: { id: this.$route.params.id },
            }"
            class="is-active is-uppercase"
            ><a>{{ name }}</a></router-link
          >
        </ul>
      </nav>
    </portal-target>
    <div class="ym-toptitle-box" v-if="order">
      <fixed-header :threshold="119" fixedClass="ym-toptitle-fixed">
        <div id="toptitle" class="level ym-toptitle">
          <div class="level-left">
            <router-link
              tag="a"
              to="/service/"
              class="button xbtn is-medium is-transparent"
              ><icon name="x"></icon
            ></router-link>
            <div>
              <p>Numer zlecenia</p>
              <h2 class="title">{{ name }}</h2>
            </div>
            <div style="margin-left: 2rem">
              <p>Status</p>
              <h2 class="title" :style="statusColor()" v-if="order.status_id">
                {{ statusName().toUpperCase() }}
              </h2>
            </div>
            <div style="margin-left: 2rem">
              <p class="label-small">Gwarancja</p>
              <h2
                class="title"
                :style="order.is_warranty ? 'color: green' : ''"
              >
                {{ order.is_warranty ? "TAK" : "NIE" }}
              </h2>
            </div>
            <div v-if="order.is_np" style="margin-left: 2rem">
              <p class="label-small">NP</p>
              <h2 class="title" :style="order.is_np ? 'color: green' : ''">
                {{ order.is_np ? "TAK" : "NIE" }}
              </h2>
            </div>
            <div
              v-if="order.warranty_status_id !== 0"
              style="margin-left: 2rem"
            >
              <p class="label-small">Status gwarancji</p>
              <h2 class="title">{{ warrantyName }}</h2>
            </div>
          </div>
          <div class="buttons level-right">
            <button
              @click="confirmTheService"
              v-if="
                order &&
                  !order.operator_confirmed &&
                  order.operator_id === $store.state.auth.user.id
              "
              class="button is-medium"
              :style="statusColor()"
            >
              <span>Potwierdz zlecenie</span>
            </button>
            <button
              v-if="
                order &&
                  this.$store.state.auth.user.email ===
                    `jakubowski@agromix.agro.pl`
              "
              @click="orderFv"
              class="button is-medium is-light"
            >
              <icon name="file-text"></icon
              ><span
                >Faktura:
                <b :style="order.is_fv ? 'color: green' : 'color: red'">{{
                  order.is_fv ? "TAK" : "NIE"
                }}</b></span
              >
            </button>
            <button
              v-if="order && order.status_id !== 40"
              @click="orderRemove"
              class="button is-medium is-light"
            >
              <icon name="trash"></icon><span>Usuń</span>
            </button>
            <a
              :href="order.pdf_order"
              target="_blank"
              class="button is-medium is-light"
              ><icon name="download"></icon>
              <div>Zlecenie serwisowe</div></a
            >
            <a
              v-if="order && order.status_id === 40"
              :href="order.pdf_protocol"
              target="_blank"
              class="button is-medium is-light"
              ><icon name="download"></icon>
              <div>Protokół Serwisowy</div></a
            >
            <router-link
              tag="a"
              :to="{
                name: 'ServiceEdit',
                params: { id: parseInt(this.$route.params.id) },
              }"
              class="button is-medium is-black"
              ><icon name="edit"></icon><span>Edytuj</span></router-link
            >
            <!-- <a class="button is-medium is-dark"><icon name="check"></icon><span>Wybierz</span></a> -->
          </div>
        </div>
      </fixed-header>
    </div>

    <div class="ym-whitebg pa40" v-if="order">
      <div class="columns" v-if="!order.operator_confirmed">
        <div class="column is-12">
          <div class="ym-infobox">
            Zlecenie serwisowe dodane przez Serwisanta. Do potwierdzenia przez:
            <b>{{ order.operator_name }}</b>
          </div>
        </div>
      </div>
      <div class="columns is-variable is-3">
        <div class="column is-4" v-if="order.number">
          <p class="label-small">Numer zlecenia</p>
          <p class="label is-uppercase">{{ order.number }}</p>
        </div>
        <div class="column is-4" v-if="order.created_at">
          <p class="label-small">Data przyjęcia</p>
          <p class="label is-uppercase">{{ order.created_at }}</p>
        </div>
        <div class="column is-4" v-if="order.status_id">
          <p class="label-small">Status zlecenia</p>
          <p class="label is-uppercase" :style="statusColor()">
            {{ statusName().toUpperCase() }}
          </p>
        </div>
      </div>
      <div class="columns is-variable is-3">
        <div class="column is-4" v-if="order.operator_name">
          <p class="label-small">
            <span v-if="order.operator_confirmed">Zlecenie przyjął</span
            ><span v-else>Zlecenie do potwierdzenia przez:</span>
          </p>
          <p class="label is-uppercase">{{ order.operator_name }}</p>
        </div>
        <div class="column is-4" v-if="order.technical_name">
          <p class="label-small">Serwisant</p>
          <p class="label is-uppercase">{{ order.technical_name }}</p>
        </div>
        <div class="column is-4" v-if="order.repared_at">
          <p class="label-small">Data naprawy</p>
          <p class="label is-uppercase">{{ order.repared_at }}</p>
        </div>
      </div>
      <hr />
      <div class="columns is-variable is-3">
        <div class="column is-4" v-if="order.customer_name">
          <p class="label-small">Klient</p>
          <p class="label is-uppercase">{{ order.customer_name }}</p>
        </div>
        <div class="column is-4" v-if="order.customer_address">
          <p class="label-small">Adres klienta</p>
          <p class="label is-uppercase">{{ order.customer_address }}</p>
        </div>
      </div>
      <div class="columns is-variable is-3">
        <div class="column is-4" v-if="order.customer_phone">
          <p class="label-small">Telefon klienta</p>
          <p class="label is-uppercase">{{ order.customer_phone }}</p>
        </div>
        <div class="column is-4" v-if="order.customer_email">
          <p class="label-small">Email klienta</p>
          <p class="label is-uppercase">{{ order.customer_email }}</p>
        </div>
        <div class="column is-4" v-if="order.customer_nip">
          <p class="label-small">NIP klienta</p>
          <p class="label is-uppercase">{{ order.customer_nip }}</p>
        </div>
      </div>
      <hr />
      <div class="columns is-variable is-3">
        <div class="column is-4" v-if="order.device_type">
          <p class="label-small">Typ maszyny</p>
          <p class="label is-uppercase">{{ order.device_type }}</p>
        </div>
        <div class="column is-4" v-if="order.device_name">
          <p class="label-small">Nazwa maszyny</p>
          <p class="label is-uppercase">{{ order.device_name }}</p>
        </div>
      </div>
      <div class="columns is-variable is-3">
        <div class="column is-4" v-if="order.device_serial">
          <p class="label-small">Numer seryjny maszyny</p>
          <p class="label is-uppercase">{{ order.device_serial }}</p>
        </div>
        <div class="column is-4" v-if="order.device_address">
          <p class="label-small">Miejsce postoju maszyny</p>
          <p class="label is-uppercase">{{ order.device_address }}</p>
        </div>
        <div class="column is-4" v-if="order.device_is_rental">
          <p class="label-small">Dzierżawa</p>
          <p class="label is-uppercase" style="color: red">TAK</p>
        </div>
      </div>
      <div class="columns is-variable is-3">
        <div class="column is-4">
          <p class="label-small">Gwarancja</p>
          <p class="label is-uppercase">
            {{ order.is_warranty ? "TAK" : "NIE" }}
          </p>
        </div>
        <div class="column is-4" v-if="order.warranty_status_id !== 0">
          <p class="label-small">Status gwarancji</p>
          <p class="label is-uppercase">{{ warrantyName }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3">
        <div class="column is-4">
          <p class="label-small">Premium</p>
          <p class="label is-uppercase">
            {{ order.is_premium ? "TAK" : "NIE" }}
          </p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3">
        <div class="column is-4">
          <p class="label-small">Liczba motogodzin</p>
          <p class="label is-uppercase">{{ order.device_hours }}</p>
        </div>
        <div class="column is-4" v-if="order.service_type_id !== 0">
          <p class="label-small">Kategoria prac</p>
          <p class="label is-uppercase">{{ order.service_type_name }}</p>
        </div>
      </div>
      <div class="columns is-variable is-3">
        <div class="column is-6" v-if="order.device_service_work">
          <p class="label-small">Czynności do wykonania</p>
          <p class="label is-uppercase">{{ order.device_service_work }}</p>
        </div>
      </div>
      <div class="columns is-variable is-3">
        <div class="column is-6" v-if="order.operator_notes">
          <p class="label-small">Uwagi operatora</p>
          <p class="label is-uppercase">{{ order.operator_notes }}</p>
        </div>
        <div class="column is-6" v-if="order.note">
          <p class="label-small">Notatka</p>
          <p class="label is-uppercase">{{ order.note }}</p>
        </div>
      </div>
    </div>
    <div class="ym-whitebg pa40" v-if="order && order.status_id === 40">
      <hr />
      <div class="columns is-mobile is-variable is-6">
        <div class="column is-12" v-if="order.service_fault">
          <p class="label-small">Opis usterki</p>
          <p class="label is-uppercase">{{ order.service_fault }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-6">
        <div class="column is-12" v-if="order.service_description">
          <p class="label-small">Opis wykonywanych czynności</p>
          <p class="label is-uppercase">{{ order.service_description }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-6">
        <div class="column is-12" v-if="order.technical_notes">
          <p class="label-small">Uwagi serwisanta</p>
          <p class="label is-uppercase">{{ order.technical_notes }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-6">
        <div class="column is-12" v-if="order.technical_service">
          <p class="label-small">Rodzaj czynności serwisanta</p>
          <p class="label is-uppercase">{{ order.technical_service }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3">
        <div class="column is-3">
          <p class="label-small">Liczba ha</p>
          <p class="label is-uppercase">{{ order.device_area }}</p>
        </div>
        <div class="column is-3">
          <p class="label-small">Liczba bali</p>
          <p class="label is-uppercase">{{ order.device_bales }}</p>
        </div>
        <div class="column is-3">
          <p class="label-small">Liczba motogodzin</p>
          <p class="label is-uppercase">{{ order.device_hours }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3">
        <div class="column is-3">
          <p class="label-small">Płatne</p>
          <p class="label is-uppercase">{{ order.is_paid ? "TAK" : "NIE" }}</p>
        </div>
        <div class="column is-3">
          <p class="label-small">Usługi</p>
          <p class="label is-uppercase">
            {{ order.is_service ? "TAK" : "NIE" }}
          </p>
        </div>
        <div class="column is-3">
          <p class="label-small">Handel</p>
          <p class="label is-uppercase">{{ order.is_trade ? "TAK" : "NIE" }}</p>
        </div>
        <div class="column is-3">
          <p class="label-small">Gwarancja</p>
          <p class="label is-uppercase">
            {{ order.is_warranty ? "TAK" : "NIE" }}
          </p>
        </div>
      </div>

      <div
        class="columns is-multiline is-mobile is-variable is-3"
        v-if="order_technical.length > 0"
      >
        <div class="column is-12">
          <p class="label-small">Serwisanci asystujący</p>
          <div
            class="column is-3"
            v-for="(technical, index) in order_technical"
            :key="technical.id"
          >
            <p class="label is-uppercase">
              {{ index + 1 }}) {{ technical.firstname.toUpperCase() }}
              {{ technical.lastname.toUpperCase() }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="columns is-multiline is-mobile is-variable is-3"
        v-if="order_commute.length > 0"
      >
        <div class="column is-12">
          <p class="label-small">Dojazd</p>
          <div class="ym-table ym-table__info">
            <div
              v-for="commute in order_commute"
              :key="commute.id"
              class="ym-tableItem ym-tableItem__prl"
            >
              <div>{{ commute.commuted_at }}</div>
              <div>
                {{ commute.city_from.toUpperCase() }}
                <!-- <small v-if="commute.time_from !== '00:00'">({{ commute.time_from }})</small> -->
              </div>
              <div>
                {{ commute.city_to.toUpperCase() }}
                <!--  <small v-if="commute.time_to !== '00:00'">({{ commute.time_to }})</small> -->
              </div>
              <div>{{ commute.distance }} km</div>
              <div>&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="columns is-multiline is-mobile is-variable is-3"
        v-if="order_work.length > 0"
      >
        <div class="column is-12">
          <p class="label-small">Godziny pracy</p>
          <div class="ym-table ym-table__info">
            <div
              v-for="work in order_work"
              :key="work.id"
              class="ym-tableItem ym-tableItem__prl"
            >
              <div>{{ work.worked_at }}</div>
              <div>{{ work.time_from }} - {{ work.time_to }}</div>
              <div>{{ work.minutes }} x{{ work.workers }}</div>
              <div>{{ work.workers_time }}</div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="columns is-mobile is-multiline is-variable is-3"
        v-if="files.length > 0"
      >
        <span v-for="(file, index) in files" :key="index" class="column is-12">
          <img :src="file.url" style="margin-bottom: 16px" />
        </span>
      </div>
      <div class="columns is-mobile is-variable is-3" v-if="order.signature">
        <div class="column is-4">
          <p class="label-small">Podpis</p>
          <img :src="order.signature_url" />
        </div>
      </div>
    </div>
    <button
      @click="resetOrder"
      class="button is-medium is-light"
      style="margin-top: 24px;"
    >
      Zresetuj zlecenie
    </button>
  </section>
</template>

<script>
import FixedHeader from "vue-fixed-header";
import { mapActions } from "vuex";
import YMmodal from "@/components/Modal.vue";

export default {
  name: "ServiceDetails",
  components: {
    FixedHeader,
  },
  data: function() {
    return {
      order: {},
      order_commute: [],
      order_work: [],
      file: null,
      files: [],
      order_technical: [],
      warrantyStatus: [],
    };
  },
  mounted() {
    this.getOrder();
    this.getCommuteItems();
    this.getWorkItems();
    this.getOrderImage();
    this.getOrderTechnical();
    this.getWarrantyStatus();
  },
  computed: {
    name: function() {
      return this.order.number;
    },
    warrantyName() {
      const status = this.warrantyStatus.find(
        (item) => item.id === this.order.warranty_status_id
      );
      return status?.name;
    },
  },
  methods: {
    ...mapActions({
      getOrderCall: "orders/getOrder",
      removeOrderCall: "orders/removeOrder",
      confirmTheServiceCall: "orders/confirmOrder",
      getCommuteItemsCall: "orders/getCommuteItems",
      getWorkItemsCall: "orders/getWorkItems",
      postOrder: "orders/postOrder",
      getOrderImageCall: "orders/getOrderImage",
      getOrderTechnicalCall: "orders/getOrderTechnical",
      getWarrantyStatusCall: "orders/warrantyStatus",
      resetOrderCall: "orders/resetOrder",
    }),
    resetOrder() {
      this.resetOrderCall({
        order: this.order,
        id: this.order.id,
        status_id: 10,
        disconnect_technical: 1,
      }).then(() => {
        this.getOrder();
      });
    },
    getOrder() {
      this.getOrderCall({ id: this.$route.params.id })
        .then((resp) => (this.order = resp))
        .catch((error) => {
          this.order = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    orderRemove() {
      this.$buefy.modal.open({
        parent: this,
        component: YMmodal,
        hasModalCard: true,
        props: {
          title: "Czy na pewno chcesz usunąć zlecenie?",
          content: `<span class='is-danger'>Usunięcia zlecenia nie można cofnąć.</span> Zlecenie pozostanie w utworzonych do tej pory protokołach.`,
          cancel: "Anuluj",
          ymHasIcon: true,
          ymIcon: "trash",
          submit: `<span class="mal50i">Usuń</span>`,
          ymConfirm: true,
          ymOnConfirm: () => {
            this.removeOrderCall(this.order)
              .then(({ data }) => {
                document.querySelector(".is-cancel").click();
                this.$router.push({ name: "Service" });
                this.$buefy.toast.open({
                  duration: 5000,
                  message: data.alert[1],
                  type: "is-success",
                  position: "is-bottom",
                });
              })
              .catch(() => {
                document.querySelector(".is-cancel").click();
              });
          },
        },
      });
    },
    confirmTheService() {
      this.$buefy.modal.open({
        parent: this,
        component: YMmodal,
        hasModalCard: true,
        props: {
          title: "Czy na pewno chcesz potwierdzić zlecenie?",
          content: `Zlecenie zostało dodane przez serwisanta.`,
          cancel: "Anuluj",
          ymHasIcon: true,
          ymIcon: "check",
          submit: `<span class="mal50i">Potwierdzam</span>`,
          ymConfirmClass: "button is-medium is-primary",
          ymConfirm: true,
          ymOnConfirm: () => {
            this.confirmTheServiceCall({ order: this.order, id: this.order.id })
              .then(({ data }) => {
                document.querySelector(".is-cancel").click();
                this.getOrder();
                this.$buefy.toast.open({
                  duration: 5000,
                  message: data.alert[1],
                  type: "is-success",
                  position: "is-bottom",
                });
              })
              .catch(() => {
                document.querySelector(".is-cancel").click();
              });
          },
        },
      });
    },
    getCommuteItems() {
      this.getCommuteItemsCall({ id: this.$route.params.id })
        .then((resp) => (this.order_commute = resp))
        .catch((error) => {
          this.order_commute = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    getWorkItems() {
      this.getWorkItemsCall({ id: this.$route.params.id })
        .then((resp) => (this.order_work = resp))
        .catch((error) => {
          this.order_work = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    orderFv() {
      const orderPost = {
        ...this.order,
        is_fv: !this.order.is_fv,
      };

      this.postOrder({ order: orderPost, id: this.$route.params.id })
        .then(() => {
          this.getOrder();
        })
        .catch((error) => {
          this.order = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error ? error.data.error_description : null,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    statusName: function() {
      let status = this.$store.state.auth.status.filter(
        (item) => item.id === this.order.status_id
      );
      if (status) {
        return status[0].name;
      }
    },
    statusColor: function() {
      let status = this.$store.state.auth.status.filter(
        (item) => item.id === this.order.status_id
      );
      if (status.length > 0) {
        return `color: ${status[0].colour}`;
      }
    },
    getOrderImage() {
      this.getOrderImageCall({ id: this.$route.params.id })
        .then((resp) => (this.files = resp))
        .catch((error) => {
          this.order_work = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    getOrderTechnical() {
      this.getOrderTechnicalCall({ id: this.$route.params.id })
        .then((resp) => (this.order_technical = resp))
        .catch((error) => {
          this.order_technical = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error ? error.data.error_description : null,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    getWarrantyStatus() {
      this.getWarrantyStatusCall()
        .then((resp) => (this.warrantyStatus = resp))
        .catch((error) => {
          this.serviceType = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped></style>
